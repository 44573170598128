<!-- 产品列表 -->
<template>
  <div style="background-color: #f2f2f2">
    <Header />
    <Sidebar />
    <div class="search-box">
      <form class="parent">
        <input
          type="text"
          class="pro-text"
          autofocus=""
          maxlength="100"
          v-model="product"
        />
        <input
          type="button"
          value="搜索产品"
          class="pro-search-btn"
          style="cursor: pointer"
          @click="searchProduct()"
        />
      </form>
    </div>
    <div class="container">
      <ul class="select">
        <li class="select-list select-level1">
          <dl>
            <dt>一级分类：</dt>
            <dd v-for="(item, index) in firstclass" :key="index">
              <a
                @click="changeFirst(item, index)"
                :class="{ 'level-choosed': isfirst == item.categoryId }"
              >
                {{ item.categoryName }}
              </a>
            </dd>
          </dl>
        </li>
        <li class="select-list select-level1">
          <dl>
            <dt>二级分类：</dt>
            <dd v-for="(item, index) in secondclass" :key="index">
              <a
                @click="changeSecond(item, index)"
                :class="{ 'level-choosed': issecond == item.categoryId }"
              >
                {{ item.categoryName }}
              </a>
            </dd>
          </dl>
        </li>
        <li class="select-list select-level1">
          <dl>
            <dt>价格区间：</dt>
            <dd v-for="(item, index) in priceList" :key="index">
              <a
                @click="choosePrice(item, index)"
                :class="{ 'level-choosed': isprice == index }"
              >
                {{ item.price }}
              </a>
            </dd>
            <dd>
              <form>
                <input
                  type="text"
                  style="width: 100px"
                  class="from-text"
                  maxlength="10"
                  v-model="productmsg.priceRange[0]"
                />
                <span>至</span>
                <input
                  type="text"
                  class="to-text"
                  maxlength="10"
                  style="margin-left: 10px; width: 100px"
                  v-model="productmsg.priceRange[1]"
                />
                <button
                  style="cursor: pointer"
                  type="button"
                  class="price-search"
                  @click="showPrice()"
                >
                  确定
                </button>
              </form>
            </dd>
          </dl>
        </li>
      </ul>
    </div>
    <div class="prt-detail">
      <div class="container">
        <div class="prt-menu-left-title">
          <h4><a>产品列表</a> <span class="text-darkred">List</span></h4>
        </div>
        <div class="prt-menu-right-title">
          <h4><a>推荐产品</a> <span class="text-darkred">Popular</span></h4>
        </div>

        <div class="prt-menu" style="padding: 30px 20px; width: 985px">
          <table cellspacing="1" class="tablesorter table-00">
            <thead>
              <tr>
                <th
                  width="80px"
                  class="header inlineword product-sort"
                  :class="{ 'text-darkred': issort == index }"
                  v-for="(item, index) in sortList"
                  :key="index"
                  @click="changeSort(item, index)"
                >
                  <span>{{ item.name }}</span>
                </th>
                <th align="right">
                  共 <label class="pro-nums"></label>{{ productList.length }} 件
                </th>
              </tr>
            </thead>
            <p v-show="productList.length == 0">暂无数据</p>
          </table>

          <div
            class="product-items"
            v-for="(item, index) in productList"
            :key="index"
          >
            <router-link :to="{ name: 'buy', query: { id: item.prodId } }">
              <div class="search-menu">
                <div class="search-menu-left">
                  <img style="width: 290px; height: 197px" :src="item.pic" />
                  <div class="sertb"><span>甄选商品</span></div>
                </div>
                <div class="search-menu-center">
                  <div>
                    <h4 class="text-over-flow">{{ item.prodName }}</h4>
                    <p>{{ item.brief }}</p>
                    <p class="tb-btn">
                      <a v-show="item.merit">{{ item.merit }}</a>
                    </p>
                  </div>
                </div>
                <div class="search-menu-right">
                  <div>
                    <a class="text-darkred" style="font-size: 30px">{{
                      item.price
                    }}</a>
                    <span>元/年</span>
                    <router-link
                      :to="{ name: 'buy', query: { id: item.prodId } }"
                    >
                      <a class="ser-btn" style="margin-top: 16px"> 在线购买 </a>
                    </router-link>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="prt-slide">
          <div
            class="prt-slide-seo"
            v-for="(item, index) in productList.slice(0, 3)"
            :key="index"
          >
            <router-link :to="{ name: 'buy', query: { id: item.prodId } }">
              <div>
                <span class="rec-pro-img-div">
                  <img :src="`${item.pic}`" />
                </span>
                <h4 class="text-overflow">{{ item.prodName }}</h4>
                <p class="text-overflow">{{ item.brief }}</p>
                <a class="text-darkred" style="font-size: 30px">{{
                  item.price
                }}</a>
                <span class="span-color">元/年</span>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header/index.vue";
import Footer from "../../components/Footer/index.vue";
import Sidebar from "../../components/Sidebar/index.vue";
import { getDetail, getAll, getShoplist, getParent } from "@/api/all.js";
export default {
  components: { Header, Footer, Sidebar },
  data() {
    return {
      //一级分类选中
      isfirst: false,
      //二级分类选中
      issecond: false,
      //价格选中
      isprice: false,
      issort: 0,
      //全部标签
      all: {
        categoryName: "全部",
        categoryId: "",
      },
      //排序列表
      sortList: [
        {
          name: "发布时间",
          sortfield: "putaway_time",
          sortdirection: true,
        },
        {
          name: "销量",
          sortfield: "sold_num",
          sortdirection: true,
        },
        {
          name: "价格",
          sortfield: "price",
          sortdirection: true,
        },
      ],
      //价格列表
      priceList: [
        {
          price: "全部",
          low: "0",
          high: "9999999",
        },
        {
          price: "0-500",
          low: "0",
          high: "500",
        },
        {
          price: "500-1000",
          low: "500",
          high: "1000",
        },
        {
          price: "1000-3000",
          low: "1000",
          high: "3000",
        },
        {
          price: "3000-5000",
          low: "3000",
          high: "5000",
        },
        {
          price: "5000-10000",
          low: "5000",
          high: "10000",
        },
        {
          price: "10000以上",
          low: "10000",
          high: "999999",
        },
        {
          price: "自定义",
          low: "",
          high: "",
        },
      ],
      // 产品 product
      product: "",
      //产品列表
      productList: [],
      //推荐列表
      recommendList: [],
      //一级分类
      firstclass: [],
      //二级分类
      secondclass: [],
      // 产品请求参数
      productmsg: {
        // 分类id
        categoryId: "",
        // 关键字
        kw: "",
        // 价格范围
        priceRange: ["0", "9999999"],
        // 是否包含子分类的数据
        showChild: true,
        // 排序方向
        sortDirection: "asc",
        // 排序字段
        sortField: "putaway_time",
      },
    };
  },
  created() {
    this.getClassify();
    this.getProduct();
    this.productmsg.categoryId = this.$route.query.categoryId;
    if (this.$route.query.categoryId) {
      this.isfirst = this.$route.query.categoryId;
      if (this.$route.query.parentId) {
        this.productmsg.categoryId = this.$route.query.parentId;
        this.issecond = this.$route.query.parentId;
      }
    }
  },
  methods: {
    //请求方法 商城列表主方法任何改变列表都会调用此方法
    getProduct() {
      getShoplist(this.productmsg).then((res) => {
        this.productList = res.data.rows;
      });
    },
    //搜索商品
    searchProduct() {
      this.productmsg.kw = this.product;
    },
    //获取分类
    getClassify() {
      //一级分类
      getParent().then((res) => {
        this.firstclass = res.data;
        this.firstclass.unshift(this.all);
      });
      //二级分类
      getAll().then((res) => {
        this.secondclass = res.data.filter((item) => item.parentId != 0);
        this.secondclass.unshift(this.all);
      });
    },
    //改变一级分类
    changeFirst(item, index) {
      //改变选中
      this.isfirst = item.categoryId;
      //改变请求值 watch监听改变就请求
      this.productmsg.categoryId = item.categoryId;
      //判断如果不是分类不为 0 就请求分类 如果是0就获取全部
      if (item.categoryName != "全部") {
        // this.all.categoryId = item.categoryId;
        getParent({ parentId: item.categoryId }).then((res) => {
          this.secondclass = res.data;
          this.secondclass.unshift(this.all);
        });
      } else {
        this.getClassify();
      }
      this.issecond = 0;
    },
    //改变二级分类
    changeSecond(item, index) {
      this.productmsg.categoryId = item.categoryId;
      //改变选中
      this.issecond = item.categoryId;
      //改变请求值 watch监听改变就请求
    },
    //改变价格
    choosePrice(item, index) {
      this.isprice = index;
      this.productmsg.priceRange = [item.low, item.high];
    },
    //排序
    changeSort(item, index) {
      this.issort = index;
      //排序字段
      this.productmsg.sortField = item.sortfield;
      if (item.sortdirection) {
        this.productmsg.sortDirection = "desc";
      } else {
        this.productmsg.sortDirection = "asc";
      }
      item.sortdirection = !item.sortdirection;
    },
    showPrice() {
      this.isprice = this.priceList.length - 1;
      // this.priceList[7] = {
      //   price:this.productmsg.priceRange[0]+'-'+this.productmsg.priceRange[1],
      //   low: this.productmsg.priceRange[0],
      //   high: this.productmsg.priceRange[1],
      // }
      this.priceList.splice(7, 1, {
        price:
          this.productmsg.priceRange[0] + "-" + this.productmsg.priceRange[1],
        low: this.productmsg.priceRange[0],
        high: this.productmsg.priceRange[1],
      });
    },
  },
  watch: {
    productmsg: {
      handler(newval, oldval) {
        this.getProduct();
      },
      //深度监听
      deep: true,
    },
    $route() {
      this.getClassify();
      this.getProduct();
      this.productmsg.categoryId = this.$route.query.categoryId;
      if (this.$route.query.categoryId) {
        this.isfirst = this.$route.query.categoryId;
        if (this.$route.query.parentId) {
          this.productmsg.categoryId = this.$route.query.parentId;
          this.issecond = this.$route.query.parentId;
        }
      }
    },
  },
};
</script>

<style scoped>
.text-darkred {
  color: #b60005;
}
.top-header {
  position: relative;
}
.head-nav {
  border-bottom: none !important;
  background-color: #fff !important;
}

.parent > input:first-of-type {
  height: 44px;
}

.inlineword {
  word-break: keep-all; /* 不换行 */
  white-space: nowrap; /* 不换行 */
}

.prt-menu tr th {
  text-align: right;
}

.prt-menu tr th span {
  margin-right: 8px;
}

.search-menu-right .ser-btn {
  width: 100% !important;
}

.search-menu-left {
  height: 197px;
}

.search-menu-right {
  width: 196px;
}

.level-choosed {
  background-color: #b60005;
  color: #fff !important;
  border-radius: 10px;
}

.search-menu .tb-btn {
  padding-left: 0px;
}
.search-menu .tb-btn a {
  border: 1px solid #b1b0b7;
  background-color: #f5f5f5;
  border-radius: 2px;
  color: #b1b0b7;
  padding: 5px 10px;
  margin-right: 5px;
  display: inline-block;
  margin-bottom: 10px;
}

.select dl dd a {
  cursor: pointer;
}

.select dl dd a.disabled {
  cursor: not-allowed;
  color: #ccc;
}

.pro-text {
  padding-left: 10px;
}

.pro-text:focus {
  background-color: #fff !important;
}

.rec-pro-btn {
  background-color: #b60005;
  display: inline-block;
  color: #fff;
  border: 1px solid #b60005;
  padding: 5px 8px;
}

.rec-pro-btn:hover {
  color: #fff;
}

.rec-pro-img-div {
  width: 200px;
  height: 130px;
  overflow: hidden;
  display: block;
}

.rec-pro-img-div img {
  width: 100%;
}

.prt-slide-seo h4 {
  margin-top: 20px;
  margin-bottom: 14px;
}

.prt-detail .container .prt-slide .prt-slide-seo div {
  padding: 3px 3px 10px;
}

.prt-slide-seo {
  border: 2px solid transparent;
}

.prt-slide-seo:hover {
  border: 2px solid #b60005;
}

.span-color {
  color: #000;
}
</style>
